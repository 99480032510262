import axios from "axios";

export const getProductOrderPendingByFacilitySupplier = async ({
  facilityID,
  supplierID,
}) => {
  return axios
    .get("/api/product-order/get-pending-by-facility-supplier", {
      params: {
        facilityID,
        supplierID,
      },
    })
    .then((res) => res.data.order);
};

export const getProductOrderByShipperToken = async (shipperToken) => {
  return axios
    .get("/api/product-order/get-by-shipper-token", {
      params: {
        shipperToken,
      },
    })
    .then((res) => res.data.order);
};

export const getProductOrderShippingLabel = async (orderID) => {
  return axios
    .get("/api/product-order/get-shipping-label", {
      params: {
        orderID,
      },
    })
    .then((res) => res.data.shippingLabels);
};

export const getUPSOrdersPendingShippingCost = async () => {
  return axios
    .get("/api/product-order/get-UPS-orders-pending-shipping-cost")
    .then((res) => res.data.availableUPSOrders);
};

export const addMultipleProductOrders = async ({
  addOrderDeliveredAgenda,
  orders,
  facilityID,
  sendOrderEmails,
}) => {
  return axios.post("/api/product-order/add-multiple", {
    addOrderDeliveredAgenda,
    orders,
    facilityID,
    sendOrderEmails,
  });
};

export const addContainerReturnOrder = async (body) => {
  return axios.post("/api/product-order/add-container-return-order", body);
};

export const updateProductOrder = async ({
  addQuickBooksBillToShipper,
  addQuickBooksBillToSupplier,
  addQuickBooksInvoice,
  addOrderDeliveredAgenda,
  order,
  sendInvoiceEmailToFacility,
  sendOrderConfirmationEmailToFacility,
  sendPOEmailToSupplier,
  sendShippingConfirmationEmails,
}) => {
  return axios
    .put("/api/product-order/update", {
      addQuickBooksBillToShipper,
      addQuickBooksBillToSupplier,
      addQuickBooksInvoice,
      addOrderDeliveredAgenda,
      order,
      sendInvoiceEmailToFacility,
      sendOrderConfirmationEmailToFacility,
      sendPOEmailToSupplier,
      sendShippingConfirmationEmails,
    })
    .then((res) => res.data.order);
};

export const updateArriveDate = async ({ orderID, arriveDate, status }) => {
  return axios
    .patch("/api/product-order/update-arrive-date", {
      orderID,
      arriveDate,
      status,
    })
    .then((res) => res.data.order);
};

export const updatePickUpAvailableDate = async (body) => {
  return axios
    .patch("/api/product-order/update-pick-up-available-date", body)
    .then((res) => res.data.order);
};

export const updateOrderAvailability = async (body) => {
  return axios
    .patch("/api/product-order/update-order-availability", body)
    .then((res) => res.data.order);
};

export const getBillOfLadingPDF = async (orderID) => {
  return axios
    .get(`/api/product-order/get-bill-of-lading-pdf?orderID=${orderID}`, {
      responseType: "blob", // Set the response type to 'blob'
    })
    .then((res) => res.data);
};

export const getCreditReceiptPDF = async (orderID) => {
  return axios
    .get(`/api/product-order/get-credit-receipt-pdf?orderID=${orderID}`, {
      responseType: "blob", // Set the response type to 'blob'
    })
    .then((res) => res.data);
};

export const resetShipping = async ({ orderID }) => {
  return axios.post("/api/product-order/reset-shipping", {
    orderID,
  });
};

export const addToOrder = async ({ orderID, orderAdditionID }) => {
  return axios
    .post("/api/product-order/add-to-order", {
      orderID,
      orderAdditionID,
    })
    .then((res) => res.data);
};

export const confirmOrder = async ({ body }) => {
  return axios.post("/api/product-order/confirm-order", body);
};

export const supplierConfirmOrder = async (formData) => {
  return axios
    .patch("/api/product-order/supplier-confirm-order", formData)
    .then((res) => res.data.order);
};

export const pickUpConfirmation = async (body) => {
  return axios.post("/api/product-order/pick-up-confirmation", body);
};

export const dropOffConfirmation = async (body) => {
  return axios.post("/api/product-order/drop-off-confirmation", body);
};

export const confirmShipping = async ({ body }) => {
  return axios.post("/api/product-order/confirm-shipping", body);
};

export const addShippingCost = async (formData) => {
  return axios.post("/api/product-order/add-shipping-cost", formData);
};

export const refund = async (formData) => {
  return axios.post("/api/product-order/refund", formData);
};

export const supplierConfirmContainersReturned = async (body) => {
  return axios.post(
    "/api/product-order/supplier-confirm-containers-returned",
    body
  );
};

export const cancelOrder = async (body) => {
  return axios.post("/api/product-order/cancel-order", body);
};
