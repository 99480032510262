import axios from "axios";

export const getFile = async (id) => {
  if (!id) return;

  return axios
    .get(`/api/file?id=${id}`, {
      responseType: "arraybuffer",
      // headers: {
      //   Authorization: `Bearer ${token}`,
      // },
    })
    .then((res) => {
      const blob = new Blob([res.data], { type: res.headers["content-type"] });
      // Create an object URL for the Blob
      const imageUrl = URL.createObjectURL(blob);
      return imageUrl;
    });
};

export const uploadMultipleFiles = async (fileIDs) => {
  if (!fileIDs) return;
  return axios
    .post(`/api/file/upload-multiple`, fileIDs)
    .then((res) => res?.data?.fileIDs || null);
};

export const deleteMultipleFiles = async (fileIDs) => {
  if (!fileIDs) return;
  return axios.post(`/api/file/delete-multiple`, fileIDs);
};
