import { useMutation, useQueryClient } from "@tanstack/react-query";
import { contactSetTermsAndConditions } from "../../api/contactAPI";

const useSetContactTermsAndConditions = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: contactSetTermsAndConditions,
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ["contact"],
      });
    },
  });
};

export default useSetContactTermsAndConditions;
