/* eslint-disable no-undef */
module.exports = {
  AUTH0_DOMAIN: window.location.origin.includes(
    "https://drops-staging.herokuapp.com"
  )
    ? process.env.REACT_APP_STAGING_AUTH0_DOMAIN
    : process.env.REACT_APP_AUTH0_DOMAIN,
  AUTH0_CLIENT_ID: window.location.origin.includes(
    "https://drops-staging.herokuapp.com"
  )
    ? process.env.REACT_APP_STAGING_AUTH0_CLIENT_ID
    : process.env.REACT_APP_AUTH0_CLIENT_ID,
  AUTH0_AUDIENCE: window.location.origin.includes(
    "https://drops-staging.herokuapp.com"
  )
    ? process.env.REACT_APP_STAGING_AUTH0_AUDIENCE
    : process.env.REACT_APP_AUTH0_AUDIENCE,
  FIREBASE_CONFIG: window.location.origin.includes(
    "https://drops-staging.herokuapp.com"
  )
    ? process.env.REACT_APP_STAGING_FIREBASE_CONFIG
    : process.env.REACT_APP_FIREBASE_CONFIG,
  FIREBASE_VAPID_KEY: window.location.origin.includes(
    "https://drops-staging.herokuapp.com"
  )
    ? process.env.REACT_APP_STAGING_FIREBASE_VAPID_KEY
    : process.env.REACT_APP_FIREBASE_VAPID_KEY,
  GOOGLE_MAP_API: window.location.origin.includes(
    "https://drops-staging.herokuapp.com"
  )
    ? process.env.REACT_APP_STAGING_GOOGLE_MAP_API
    : process.env.REACT_APP_GOOGLE_MAP_API,
  GOOGLE_TRACKING_ID: window.location.origin.includes(
    "https://drops-staging.herokuapp.com"
  )
    ? process.env.REACT_APP_STAGING_GOOGLE_TRACKING_ID
    : process.env.REACT_APP_GOOGLE_TRACKING_ID,
  MUI_LICENSE: process.env.REACT_APP_MUI_LICENSE,
  SENTRY_DSN: window.location.origin.includes(
    "https://drops-staging.herokuapp.com"
  )
    ? process.env.REACT_APP_STAGING_SENTRY_DSN
    : process.env.REACT_APP_SENTRY_DSN,
};
