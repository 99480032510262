import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { loadState } from "../../localStorage";

const preloadedState = loadState("facilities");
const initialState = {
  facility: preloadedState?.facility ?? null,
  facilities: preloadedState?.facilities ?? [],
  status: preloadedState?.status ?? "idle",
  error: preloadedState?.error ?? null,
};

export const fetchFacilities = createAsyncThunk(
  "facilities/fetchFacilities",
  async (obj) => {
    try {
      if (obj.superUser) {
        const res = await axios.get(`/api/facility/get-all`);
        return res.data.facilities;
      }
      const res = await axios.get(
        `/api/facility/get-all-by-contact?id=${obj.contactId}`
      );
      return res.data.facilities;
    } catch (error) {
      return error.message;
    }
  }
);

export const updateFacility = createAsyncThunk(
  "facility/update",
  async (body, { rejectWithValue }) => {
    try {
      const res = await axios.post(`/api/facility/update`, body);
      return res.data;
    } catch (error) {
      if (error.response?.data?.error?.code === 11000) {
        return rejectWithValue({
          errorMessage: "A facility with this name already exists",
        });
      }
      return rejectWithValue({
        errorMessage: error.message,
      });
    }
  }
);

export const facilitiesSlice = createSlice({
  name: "facilityState",
  initialState,
  reducers: {
    resetFacility: (state) => {
      state.facility = null;
      state.facilities = [];
      state.status = "idle";
      state.error = null;
    },
    setFacilities: (state, action) => {
      state.facilities = action.payload;
    },
    setFacilityStatus: (state, action) => {
      state.status = action.payload;
    },
    setFacility: (state, action) => {
      state.facility = action.payload;
    },
    addFacility: (state, action) => {
      state.facilities.push(action.payload);
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchFacilities.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchFacilities.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.facilities = action.payload;
      })
      .addCase(fetchFacilities.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })

      .addCase(updateFacility.fulfilled, (state, action) => {
        state.facility = action.payload;
      });
  },
});

export const getFacilityError = (state) => state.facilities.error;
export const selectAllFacilities = (state) => state.facilities.facilities;
export const selectFacility = (state) => state.facilities.facility;
export const selectFacilityById = (state, facilityId) =>
  state.facilities.facilities.find((facility) => facility._id === facilityId);

export const {
  addFacility,
  setFacilities,
  setFacilityStatus,
  setFacility,
  resetFacility,
} = facilitiesSlice.actions;

export default facilitiesSlice.reducer;
