import React, { useEffect, useState } from "react";
import {
  Autocomplete,
  Box,
  Button,
  FormControl,
  FormControlLabel,
  Grid2,
  InputAdornment,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import moment from "moment";
import { getProductOptionLabel } from "../../sharedFunctions/labels";
import { FormSwitch } from "../sharedComponents";
import CustomDatePicker from "../sharedComponents/datePicker/CustomDatePicker";
import DecimalTextField from "../sharedComponents/TextFields/DecimalTextField";
import InfoText from "../sharedComponents/InfoText";
import FileList from "../sharedComponents/FileList";
import SuperUserShipperSelection from "./SuperUserShipperSelection";
import axios from "axios";
import { updatePalletDimensions } from "../../sharedFunctions/palletBuildingFunctions/palletBuildingFunctions";
import useSupplierConfirmOrder from "../../customHooks/productOrder/useSupplierConfirmOrder";

const PrivateDropsShippingOrderConfirmation = ({
  appUserLevel,
  close,
  order,
}) => {
  const supplierConfirmOrder = useSupplierConfirmOrder();
  const editMode =
    order?.status === "Awaiting Stock" ||
    order?.status === "Processing" ||
    order?.status === "Pending"
      ? true
      : false;

  return (
    <DropsShippingOrderConfirmation
      appUserLevel={appUserLevel}
      close={close}
      editMode={editMode}
      order={order}
      supplierConfirmOrder={async (body) =>
        await supplierConfirmOrder.mutateAsync(body)
      }
    />
  );
};

const DropsShippingOrderConfirmation = ({
  appUserLevel,
  close,
  editMode,
  order,
  supplierConfirmOrder,
}) => {
  const [addPickUpHours, setAddPickUpHours] = useState(
    !!order?.warehouse?.contact?.pickUpHours
  );
  const [addShippingDimensions, setAddShippingDimensions] = useState(
    !!(
      (order.shippingDimensionUnit && order.shippingWeightUnit) ||
      (order?.warehouse?.contact?.shippingDimensionUnit &&
        order?.warehouse?.contact?.shippingWeightUnit)
    )
  );
  const [customDimensions, setCustomDimensions] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [files, setFiles] = useState([]);
  const [packages, setPackages] = useState(
    order.packages?.length
      ? order.packages.map((p) => ({
          ...p,
          type: "Package",
        }))
      : []
  );

  const [pallets, setPallets] = useState(
    order?.pallets?.length
      ? order?.pallets.map((pallet) => ({
          ...pallet,
          products: pallet.products.map((product) => ({
            ...product.product,
            manufacturerProductDetail: order.productDetails.find(
              (pd) => pd.product._id === product.product._id
            ).manufacturerProductDetail,
            quantity: product.quantity,
          })),
          type: "Pallet",
        }))
      : []
  );

  const [pickUpAvailableDate, setPickUpAvailableDate] = useState(
    order?.pickUpAvailableDate ? moment(order?.pickUpAvailableDate) : null
  );
  const [pickUpHours, setPickUpHours] = useState(
    order?.warehouse?.contact?.pickUpHours?.open
      ? `${moment(order.warehouse.contact.pickUpHours.open, "HH:mm").format(
          "hh:mm A"
        )} - ${moment(
          order?.warehouse.contact.pickUpHours.close,
          "HH:mm"
        ).format("hh:mm A")}`
      : ""
  );
  const [saving, setSaving] = useState(false);
  const [shippingDimensionUnit, setShippingDimensionUnit] = useState(
    order?.shippingDimensionUnit ??
      order?.warehouse?.contact?.shippingDimensionUnit ??
      ""
  );
  const [shippingNotes, setShippingNotes] = useState(
    order?.shippingNotes ?? ""
  );
  const [shippingWeightUnit, setShippingWeightUnit] = useState(
    order?.shippingWeightUnit ??
      order?.warehouse?.contact?.shippingWeightUnit ??
      ""
  );
  const [shipper, setShipper] = useState(order?.shipper ?? null);
  const [status, setStatus] = useState(
    order?.pickUp ? "Ready For Pick Up" : "Ready For Delivery"
  );
  const [supplierOrderNumber, setSupplierOrderNumber] = useState(
    order?.supplierOrderNumber ?? ""
  );
  const [supplierPallets, setSupplierPallets] = useState([]);
  const [valid, setValid] = useState(false);

  useEffect(() => {
    if (!order?.supplier?._id) return;
    axios
      .get(
        `/api/public-form/get-pallets-by-supplier?supplierID=${order?.supplier?._id}`
      )
      .then((res) =>
        setSupplierPallets(res.data.manufacturerProductDetailPallets)
      );
  }, [order?.supplier?._id]);

  const onSetPallets = (pallets) => {
    if (!supplierPallets?.length || !pallets?.length || customDimensions)
      return setPallets(pallets);

    const updatedPallets = updatePalletDimensions({
      pallets,
      supplierPallets,
      shippingDimensionUnit,
      shippingWeightUnit,
    });

    setPallets(updatedPallets ?? pallets);
  };

  useEffect(() => {
    if (
      (status === "Ready For Delivery" || status === "Ready For Pick Up") &&
      !pickUpAvailableDate
    ) {
      setValid(false);
      setErrorMessage("Please Provide a Ship Date");
      return;
    }

    if (status === "Ready For Delivery" || status === "Ready For Pick Up") {
      if (addPickUpHours && !pickUpHours) {
        setValid(false);
        setErrorMessage("Please Provide Pick Up Hours");
        return;
      }
      if (addShippingDimensions) {
        if (!shippingDimensionUnit || !shippingWeightUnit) {
          setValid(false);
          setErrorMessage(
            "Please Provide Shipping Dimensions and Weight Units"
          );
          return;
        }
      }

      if (
        pallets.filter(
          (pallet) =>
            (addShippingDimensions && !pallet.shippingInfoHeight) ||
            (addShippingDimensions && !pallet.shippingInfoLength) ||
            (addShippingDimensions && !pallet.shippingInfoWidth) ||
            (addShippingDimensions && !pallet.shippingInfoWeight) ||
            (addShippingDimensions && !pallet.shippingInfoQuantity) ||
            pallet.products.filter((product) => !product).length
        ).length
      ) {
        setValid(false);
        setErrorMessage(
          "Please fill out shipping dimensions for all pallets and ensure there are no empty products"
        );
        return;
      }
      if (
        packages.filter(
          (item) =>
            (addShippingDimensions && !item.shippingInfoHeight) ||
            (addShippingDimensions && !item.shippingInfoLength) ||
            (addShippingDimensions && !item.shippingInfoWidth) ||
            (addShippingDimensions && !item.shippingInfoWeight) ||
            (addShippingDimensions && !item.shippingInfoQuantity) ||
            item.products.filter((product) => !product).length
        ).length
      ) {
        setValid(false);
        setErrorMessage(
          "Please fill out shipping dimensions for all packages and ensure there are no empty products"
        );
        return;
      }

      const relevantProductDetails =
        !order?.pickUp && order.containersToReturn?.length
          ? order.containersToReturn.filter(
              (c) => c.container.containerType !== "Pallet"
            )
          : order.productDetails.filter(
              (pd) => pd.product.type !== "Container"
            );

      // Check each relevant product detail for quantity fulfillment
      for (let productDetail of relevantProductDetails) {
        const product = productDetail.product ?? productDetail.chemical;
        const requiredQuantity = productDetail.quantity;

        // Calculate the total quantity added for this product from packages and pallets
        const totalQuantityAdded = [...packages, ...pallets].reduce(
          (total, container) => {
            return (
              total +
              container.products.reduce((subtotal, product2) => {
                return product2?._id === product?._id
                  ? subtotal +
                      parseInt(product2.quantity) *
                        parseInt(container.shippingInfoQuantity)
                  : subtotal;
              }, 0)
            );
          },
          0
        );

        // If the total added quantity doesn't match the required quantity, set error and exit
        if (totalQuantityAdded !== requiredQuantity) {
          setErrorMessage(
            "Make sure all products have been added to a package or pallet " +
              product.name +
              totalQuantityAdded +
              "/" +
              requiredQuantity
          );
          return setValid(false);
        }
      }
    }
    setErrorMessage("");

    setValid(true);
  }, [
    addPickUpHours,
    addShippingDimensions,
    order,
    order.pickUp,
    order.productDetails,
    packages,
    pallets,
    pickUpAvailableDate,
    pickUpHours,
    shippingDimensionUnit,
    shippingWeightUnit,
    status,
  ]);

  const addPackage = () => {
    setPackages([
      ...packages,
      {
        notes: "",
        products: [""],
        shippingInfoHeight: "",
        shippingInfoLength: "",
        shippingInfoWidth: "",
        shippingInfoWeight: "",
        shippingInfoQuantity: 1,
        type: "Package",
      },
    ]);
  };

  const addPallet = () => {
    onSetPallets([
      ...pallets,
      {
        notes: "",
        products: [""],
        shippingInfoHeight: "",
        shippingInfoLength: "",
        shippingInfoWidth: "",
        shippingInfoWeight: "",
        shippingInfoQuantity: 1,
        type: "Pallet",
      },
    ]);
  };

  const submitConfirmOrder = async () => {
    setSaving(true);

    const formData = new FormData();

    if (files?.length) {
      for (let i = 0; i < files.length; i++) {
        formData.append("files[]", files[i]);
      }
    }

    let body = {
      ...order,
      pickUpAvailableDate,
      shippingNotes,
      supplierOrderNumber,
      status,
      useDrOPsShipping: true,
    };

    if (status === "Ready For Delivery" || status === "Ready For Pick Up") {
      body = {
        ...body,
        addShippingDimensions,
        pallets: pallets.map((pallet) => {
          return {
            ...pallet,
            products: pallet.products.map((product) => {
              return {
                product: product._id,
                quantity: parseInt(product.quantity),
              };
            }),
          };
        }),
        packages,
        pickUpHours,
        shippingDimensionUnit,
        shippingWeightUnit,
        shipper: shipper?._id,
      };
    }

    formData.append("order", JSON.stringify(body));

    await supplierConfirmOrder(formData);

    close();
    setSaving(false);
  };

  const renderProducts = (item, items, i, setItems) => {
    let options = [];
    if (order?.containersToReturn?.length > 0 && !order.pickUp) {
      options = order.containersToReturn
        .map((CTR) => ({
          ...CTR.chemical,
          manufacturerProductDetail: CTR.manufacturerProductDetail,
        }))
        .filter(
          (product) =>
            !item.products.some((product2) => product?._id === product2?._id)
        );
    } else {
      options = order.productDetails
        .filter(
          (product) =>
            !product.isReturn && product.product.containerType !== "Pallet"
        )
        .map((productDetail) => {
          const isSelected = item.products.some(
            (product) => product?._id === productDetail.product?._id
          );
          return {
            ...productDetail.product,
            manufacturerProductDetail: productDetail.manufacturerProductDetail,
            disabled: isSelected,
          };
        });
    }

    return (
      <Grid2 container spacing={2} size={12}>
        {editMode && (
          <Grid2 size={12}>
            <Button
              variant="contained"
              fullWidth
              onClick={() => {
                const tempItem = { ...items[i] };
                tempItem.products = [...tempItem.products, ""];
                const tempItems = [...items];
                tempItems[i] = tempItem;
                setItems(tempItems);
              }}
            >
              Add Product
            </Button>
          </Grid2>
        )}
        {item.products.map((product, y) => (
          <Grid2
            container
            spacing={2}
            key={"pallet" + i + "product" + y}
            size={12}
          >
            <Grid2
              size={{
                xs: 12,
                md: 8,
              }}
            >
              <Autocomplete
                options={options}
                getOptionDisabled={(option) => option?.disabled}
                getOptionLabel={getProductOptionLabel}
                value={product}
                isOptionEqualToValue={(option, value) => {
                  if (!value.length) return [];
                  return option._id === value._id;
                }}
                onChange={(e, value) => {
                  const tempItem = { ...items[i] };
                  tempItem.products[y] = { ...value, quantity: 1 };
                  const tempItems = [...items];
                  tempItems[i] = tempItem;
                  setItems(tempItems);
                }}
                renderInput={(params) => (
                  <TextField
                    autoComplete="off"
                    {...params}
                    label="Product"
                    required
                  />
                )}
                disabled={!editMode}
              />
            </Grid2>
            <Grid2
              size={{
                xs: 8,
                md: 2,
              }}
            >
              <DecimalTextField
                label={"Product Qty"}
                value={product?.quantity ?? 1}
                editMode={editMode}
                onChange={(e) => {
                  const tempItem = { ...items[i] };
                  const tempProduct = { ...tempItem.products[y] };
                  tempProduct.quantity = e.target.value;
                  tempItem.products[y] = tempProduct;

                  const tempItems = [...items];
                  tempItems[i] = tempItem;
                  setItems(tempItems);
                }}
                allowMinus={false}
                required={true}
              />
            </Grid2>

            {editMode && (
              <Grid2
                display="flex"
                size={{
                  xs: 4,
                  md: 2,
                }}
              >
                <Button
                  variant="contained"
                  fullWidth
                  color="error"
                  onClick={() => {
                    const tempItem = { ...items[i] };
                    tempItem.products.splice(y, 1);
                    const tempItems = [...items];
                    tempItems[i] = tempItem;
                    if (!tempItem.products.length) tempItems.splice(i, 1);
                    setItems(tempItems);
                  }}
                >
                  Remove
                </Button>
              </Grid2>
            )}
          </Grid2>
        ))}
      </Grid2>
    );
  };

  const renderDetails = (item, items, setItems, i) => {
    return (
      <Grid2 container spacing={2} key={"pallet" + i} size={12}>
        <Grid2
          className="vertical-centered-container"
          size={{
            xs: 4,
            md: 2,
          }}
        >
          <Typography>
            {item.type} {i + 1}
          </Typography>
        </Grid2>
        {renderProducts(item, items, i, setItems)}
        {addShippingDimensions && (
          <Grid2 container spacing={2} size={12}>
            <Grid2
              size={{
                xs: 4,
                md: 2.5,
              }}
            >
              <DecimalTextField
                label={"L"}
                value={item.shippingInfoLength}
                editMode={editMode}
                onChange={(e) => {
                  const tempItem = { ...items[i] };
                  tempItem.shippingInfoLength = e.target.value;
                  const tempItems = [...items];
                  tempItems[i] = tempItem;
                  setItems(tempItems);
                }}
                inputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      {shippingDimensionUnit}
                    </InputAdornment>
                  ),
                }}
                allowMinus={false}
                required={true}
              />
            </Grid2>
            <Grid2
              size={{
                xs: 4,
                md: 2.5,
              }}
            >
              <DecimalTextField
                label={"W"}
                value={item.shippingInfoWidth}
                editMode={editMode}
                onChange={(e) => {
                  const tempItem = { ...items[i] };
                  tempItem.shippingInfoWidth = e.target.value;
                  const tempItems = [...items];
                  tempItems[i] = tempItem;
                  setItems(tempItems);
                }}
                inputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      {shippingDimensionUnit}
                    </InputAdornment>
                  ),
                }}
                allowMinus={false}
                required={true}
              />
            </Grid2>
            <Grid2
              size={{
                xs: 4,
                md: 2.5,
              }}
            >
              <DecimalTextField
                label={"H"}
                value={item.shippingInfoHeight}
                editMode={editMode}
                onChange={(e) => {
                  const tempItems = [...items];
                  const tempItem = { ...items[i] };
                  tempItem.shippingInfoHeight = e.target.value;
                  tempItems[i] = tempItem;
                  setItems(tempItems);
                }}
                inputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      {shippingDimensionUnit}
                    </InputAdornment>
                  ),
                }}
                allowMinus={false}
                required={true}
              />
            </Grid2>
            <Grid2
              size={{
                xs: 4,
                md: 2.5,
              }}
            >
              <DecimalTextField
                label={"W"}
                value={item.shippingInfoWeight}
                editMode={editMode}
                onChange={(e) => {
                  const tempItem = { ...items[i] };
                  tempItem.shippingInfoWeight = e.target.value;
                  const tempItems = [...items];
                  tempItems[i] = tempItem;
                  setItems(tempItems);
                }}
                inputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      {shippingWeightUnit}
                    </InputAdornment>
                  ),
                }}
                allowMinus={false}
                required={true}
              />
            </Grid2>
            <Grid2
              size={{
                xs: 8,
                md: 12,
              }}
            >
              <Typography>
                * Please verify the dimensions are accurate.
              </Typography>
            </Grid2>
          </Grid2>
        )}
        <Grid2 container spacing={2} size={12}>
          <Grid2
            size={{
              xs: 4,
              md: 2,
            }}
          >
            <DecimalTextField
              label={`${item.type} Qty`}
              value={item.shippingInfoQuantity}
              editMode={editMode}
              onChange={(e) => {
                const tempItem = { ...items[i] };
                tempItem.shippingInfoQuantity = e.target.value;
                const tempItems = [...items];
                tempItems[i] = tempItem;
                setItems(tempItems);
              }}
              allowMinus={false}
              required={true}
            />
          </Grid2>
          <Grid2
            size={{
              xs: 8,
              md: 10,
            }}
          >
            <TextField
              autoComplete="off"
              fullWidth
              variant="outlined"
              label="Notes"
              value={item.notes}
              multiline
              onChange={(e) => {
                const tempItem = { ...items[i] };
                tempItem.notes = e.target.value;
                const tempItems = [...items];
                tempItems[i] = tempItem;
                setItems(tempItems);
              }}
              disabled={!editMode}
            />
          </Grid2>
        </Grid2>
        {editMode && (
          <Grid2 size={12}>
            <Button
              color="error"
              fullWidth
              variant="contained"
              onClick={() => {
                const tempItems = [...items];
                tempItems.splice(i, 1);
                setItems(tempItems);
              }}
            >
              Remove {item.type}
            </Button>
          </Grid2>
        )}
      </Grid2>
    );
  };

  return (
    <Grid2 container spacing={2} size={12}>
      <Grid2 size={12}>
        <Typography>
          {order.pickUp
            ? "Item has been requested to be picked up, please input pick up details."
            : "Item has been requested to be shipped, please input shipping details."}
        </Typography>
      </Grid2>
      <Grid2 size={12}>
        <Typography>Order Number: {order.orderNumber}</Typography>
      </Grid2>
      <Grid2 size={12}>
        <Typography>Warehouse: {order.warehouse?.contact.name}</Typography>
      </Grid2>
      <Grid2 container spacing={2} size={12}>
        <Grid2 size={12}>
          <RadioGroup
            value={status}
            onChange={(e) => {
              setStatus(e.target.value);
            }}
            row
            sx={{ display: "flex", justifyContent: "space-around" }}
          >
            <Box display={"flex"}>
              <FormControlLabel
                value={
                  order.pickUp ? "Ready For Pick Up" : "Ready For Delivery"
                }
                control={
                  <Radio
                    onChange={() => {
                      setShippingDimensionUnit(
                        order?.warehouse?.contact?.shippingDimensionUnit ?? ""
                      );
                      setShippingWeightUnit(
                        order?.warehouse?.contact?.shippingWeightUnit ?? ""
                      );
                      setPickUpHours(
                        order?.warehouse?.contact?.pickUpHours ?? ""
                      );
                    }}
                    disabled={!editMode}
                  />
                }
                label="Ready To Ship"
                sx={{ marginRight: 0 }}
              />
              <InfoText
                text="Select this option if the shipment dimensions and weight are available."
                disableMargin
              />
            </Box>
            <Box display={"flex"}>
              <FormControlLabel
                value={"Awaiting Stock"}
                control={
                  <Radio
                    onChange={() => {
                      setPackages([]);
                      setPallets([]);
                      setShippingDimensionUnit("");
                      setShippingWeightUnit("");
                      setPickUpHours("");
                    }}
                    disabled={!editMode}
                  />
                }
                label="Back Ordered"
                sx={{ marginRight: 0 }}
              />
              <InfoText
                text="Select this option if the order is not in stock."
                disableMargin
              />
            </Box>
            <Box display={"flex"}>
              <FormControlLabel
                value={"Processing"}
                control={
                  <Radio
                    onChange={() => {
                      setPackages([]);
                      setPallets([]);
                      setShippingDimensionUnit("");
                      setShippingWeightUnit("");
                      setPickUpHours("");
                    }}
                    disabled={!editMode}
                  />
                }
                label="Processing"
                sx={{ marginRight: 0 }}
              />
              <InfoText
                text="Select this option if the order is in stock but awaiting the shipping details."
                disableMargin
              />
            </Box>
          </RadioGroup>
        </Grid2>

        <Grid2
          size={{
            xs: 6,
            md: 4,
          }}
        >
          <CustomDatePicker
            required
            label={
              status === "Ready For Delivery"
                ? "Ship Date"
                : "est. Available Date"
            }
            onChange={(value) => {
              setPickUpAvailableDate(value);
            }}
            value={pickUpAvailableDate}
            disabled={!editMode}
          />
        </Grid2>
        <Grid2
          size={{
            xs: 6,
            md: 4,
          }}
        >
          <TextField
            autoComplete="off"
            fullWidth
            variant="outlined"
            label="Supplier Order #"
            value={supplierOrderNumber}
            onChange={(e) => {
              setSupplierOrderNumber(e.target.value);
            }}
            disabled={!editMode}
          />
        </Grid2>
        {!order.pickUp && appUserLevel?.includes("Super User") && (
          <SuperUserShipperSelection
            order={order}
            shipper={shipper}
            setShipper={setShipper}
          />
        )}

        {(status === "Ready For Delivery" ||
          status === "Ready For Pick Up") && (
          <>
            <FormSwitch
              checked={addShippingDimensions}
              onChange={(e) => setAddShippingDimensions(e.target.checked)}
              label={"Shipping Dimensions"}
              xs={6}
              md={4}
              disabled={!editMode}
            />
            <FormSwitch
              checked={addPickUpHours}
              onChange={(e) => setAddPickUpHours(e.target.checked)}
              label={"Pick Up Hours"}
              xs={6}
              md={4}
              disabled={!editMode}
            />

            {addShippingDimensions && (
              <>
                <Grid2
                  size={{
                    xs: 6,
                    md: 4,
                  }}
                >
                  <FormControl fullWidth>
                    <InputLabel required id="dimensionUnitLabel">
                      Dimension Unit
                    </InputLabel>
                    <Select
                      value={shippingDimensionUnit}
                      onChange={(e) => {
                        setShippingDimensionUnit(e.target.value);
                      }}
                      labelId="dimensionUnitLabel"
                      label="Dimension Unit"
                      disabled={!editMode}
                    >
                      <MenuItem value={"cm"}>cm</MenuItem>
                      <MenuItem value={"inch"}>inch</MenuItem>
                      <MenuItem value={"feet"}>feet</MenuItem>
                      <MenuItem value={"m3"}>m3</MenuItem>
                    </Select>
                  </FormControl>
                </Grid2>
                <Grid2
                  size={{
                    xs: 6,
                    md: 4,
                  }}
                >
                  <FormControl fullWidth>
                    <InputLabel required id="weightUnitLabel">
                      Weight Unit
                    </InputLabel>

                    <Select
                      value={shippingWeightUnit}
                      onChange={(e) => {
                        setShippingWeightUnit(e.target.value);
                      }}
                      labelId="weightUnitLabel"
                      label="Weight Unit"
                      disabled={!editMode}
                    >
                      <MenuItem value={"lbs"}>lbs</MenuItem>
                      <MenuItem value={"kg"}>kg</MenuItem>
                      <MenuItem value={"tonne"}>tonne</MenuItem>
                    </Select>
                  </FormControl>
                </Grid2>
              </>
            )}
            {addPickUpHours && (
              <Grid2
                size={{
                  xs: 12,
                  md: 4,
                }}
              >
                <TextField
                  autoComplete="off"
                  required
                  fullWidth
                  variant="outlined"
                  label="Pick Up Hours"
                  value={pickUpHours}
                  onChange={(e) => {
                    setPickUpHours(e.target.value);
                  }}
                  disabled={!editMode}
                />
              </Grid2>
            )}
            {editMode && (
              <Grid2 container spacing={2} size={12}>
                <Grid2 size={6}>
                  <Button variant="contained" fullWidth onClick={addPackage}>
                    Add Package
                  </Button>
                </Grid2>

                <Grid2 size={6}>
                  <Button variant="contained" fullWidth onClick={addPallet}>
                    Add Pallet
                  </Button>
                </Grid2>
              </Grid2>
            )}
            {addShippingDimensions && (
              <FormSwitch
                checked={customDimensions}
                onChange={(e) => setCustomDimensions(e.target.checked)}
                label={"Custom Dimensions"}
                xs={12}
                md={4}
                disabled={!editMode}
              />
            )}
            {packages.map((item, i) =>
              renderDetails(item, packages, setPackages, i)
            )}
            {pallets.map((item, i) =>
              renderDetails(item, pallets, onSetPallets, i)
            )}
          </>
        )}
        <Grid2 size={12}>
          <TextField
            autoComplete="off"
            fullWidth
            variant="outlined"
            label="Additional Shipping Notes"
            value={shippingNotes}
            multiline
            onChange={(e) => setShippingNotes(e.target.value)}
            disabled={!editMode}
          />
        </Grid2>
        <Grid2 size={12}>
          <Typography>Attachments</Typography>
        </Grid2>
        {order?.files?.length > 0 &&
          (appUserLevel?.includes("Super User") ||
            appUserLevel?.includes("Supplier")) && (
            <FileList files={order?.files} />
          )}
        {editMode && (
          <Grid2 size={12}>
            <input
              onChange={(e) => {
                setFiles(e.target.files);
              }}
              type="file"
              accept="application/pdf"
              multiple
            />
          </Grid2>
        )}
        {editMode && errorMessage && (
          <Grid2 className="centered-container" size={12}>
            <Typography color={"error"}>{errorMessage}</Typography>
          </Grid2>
        )}

        {editMode && (
          <Grid2 size={12}>
            <Button
              variant="contained"
              fullWidth
              onClick={submitConfirmOrder}
              disabled={!valid || saving}
            >
              {saving ? "Confirming" : "Confirm"}
            </Button>
          </Grid2>
        )}
      </Grid2>
    </Grid2>
  );
};

const ContainerToUse = ({
  appUserLevel,
  close,
  editMode,
  order,
  publicComponent,
}) => {
  if (publicComponent) {
    return (
      <DropsShippingOrderConfirmation
        appUserLevel={appUserLevel}
        close={close}
        editMode={editMode}
        order={order}
        supplierConfirmOrder={(formData) =>
          axios.patch("/api/public-form/supplier-confirm-order", formData)
        }
      />
    );
  } else {
    return (
      <PrivateDropsShippingOrderConfirmation
        appUserLevel={appUserLevel}
        close={close}
        order={order}
      />
    );
  }
};

export default ContainerToUse;
