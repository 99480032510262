export const DISTRIBUTION_TAB_NUMBER = 0;
export const BIOLOGICAL_FILTER_TAB_NUMBER = 1;
export const GREENSAND_FILTER_TAB_NUMBER = 2;
export const UF_SYSTEM_TAB_NUMBER = 3;
export const RO_TAB_NUMBER = 4;
export const CHEMICALS_TAB_NUMBER = 5;
export const PRE_TREATMENT_TAB_NUMBER = 6;
export const POST_TREATMENT_TAB_NUMBER = 7;
export const LAGOON_TAB_NUMBER = 8;

export const SYSTEMS_TAB_NUMBER = 0;
export const SUPPLIES_TAB_NUMBER = 1;
export const ACTIONS_TAB_NUMBER = 2;
export const MAINTENANCE_TAB_NUMBER = 3;
export const INFO_TAB_NUMBER = 4;

export const SUPPLIER_PRODUCTS_TAB_NUMBER = 0;
export const SUPPLIER_SERVICES_TAB_NUMBER = 1;
export const SUPPLIER_ACTIONS_TAB_NUMBER = 2;
export const SUPPLIER_COMPANY_TAB_NUMBER = 3;
export const SUPPLIER_CONTACT_TAB_NUMBER = 4;

// Reports Tab Numbers
export const REPORTS_MONTHLY_TAB_NUMBER = 0;
export const REPORTS_YEARLY_TAB_NUMBER = 1;
export const REPORTS_CERTIFICATE_TAB_NUMBER = 2;

export const COMMISSION = 0.15;
export const COMMISSION_DROPS = 0;
export const COMMISSION_SHIPPER = 0.1;
export const GST = 0.05;

export const DROP_WATER_CONTACT_ID = "61859068750e779e33f1c66f";
export const UPS_CONTACT_ID = "63bc51d95847a15015cfb005";
export const CANADA_POST_CONTACT_ID = "63bc52075847a15015cfb01a";
export const FEDEX_CONTACT_ID = "63bc51e25847a15015cfb00c";

export const facilityTypes = [
  "Colony",
  "First Nation",
  "Food Production",
  "Government",
  "Industrial",
  "Municipal",
  "Private",
];
export const productTypes = [
  "Chemical",
  "Container",
  "Equipment",
  "Filter",
  "Hollow Fiber Membrane",
  "Janitorial Supplies",
  "Part",
  "Spiral Wound Membrane",
  "Testing and Calibration Materials",
  "Other",
];

export const CHEMICAL_TYPES = [
  "Antiscalant",
  "Coagulant",
  "Corrosion Inhibitor",
  "Mineral",
  "RO/NF Cleaning Chemical",
  "UF/MF Cleaning Chemical",
  "Other",
];

export const containerTypes = [
  "Bag",
  "Jar (2 lbs)",
  "Carboy (5 gal)",
  "Drum (30 gal)",
  "Drum (55 gal)",
  "Jerry Can (5 gal)",
  "Pail (5 gal)",
  "Tote (330 gal)",
  "Pallet",
  "Cylinder",
  "Tanker",
];

export const flowUnits = [
  "gpm",
  "gpd",
  "i-gpm",
  "i-gpd",
  "L/s",
  "L/min",
  "L/h",
  "L/day",
  "m3/h",
  "m3/day",
];

export const serviceTypes = [
  "Automation",
  "Electrical",
  "General",
  "Mechanical",
  "Operations",
  "Other",
  "Travel and Accommodation",
];

export const superUserEmails = [
  "jake@dropsolutions.ca",
  "braydon@dropsolutions.ca",
  "dillon@dropsolutions.ca",
  "emily@dropsolutions.ca",
  "brittany@dropsolutions.ca",
  "susanne@dropsolutions.ca",
  "david@dropsolutions.ca",
];

export const superUserDeveloperEmails = [
  "jake@dropsolutions.ca",
  "braydon@dropsolutions.ca",
];

export const notificationTypes = [
  "product price expired",
  "renew service contract",
  "shipment created",
  "add to shipment",
  "replace membranes",
  "replace all membranes",
  "replace filter",
  "reorder chemical",
  "reorder membranes",
  "reorder all membranes",
  "reorder filter",
  "reorder module",
  "maintenance required",
  "log reminder",
  "perform backwash",
  "replace greensand media",
  "perform air scour and backwash",
  "replace bioFilter media",
  "bioFilter lateral system",
  "recharge greensand media",
  "membrane issue",
  "order delivered confirmation",
  "service contract expired",
  "perform ceb",
  "perform cip",
  "replace module",
  "replace modules",
  "perform pinning/sealing repair",
];
