import React from "react";
import { TextField } from "@mui/material";

const DecimalTextField = (props) => {
  const {
    inputRef,
    editMode,
    defaultValue,
    value,
    name,
    onChange,
    label,
    required = true,
    size,
    sx,
    numberProps,
    inputProps,
    allowMinus = true,
    allowDecimal = true,
    inputLabelProps,
  } = props;

  const onKeyDown = (e) => {
    const { key } = e;
    if ((key === "-" && !allowMinus) || (key === "." && !allowDecimal)) {
      e.preventDefault();
    }
    if (["e", "E", "+"].includes(key)) {
      e.preventDefault();
    }
  };

  return (
    <TextField
      inputRef={inputRef}
      type="number"
      required={required}
      name={name}
      autoComplete="off"
      label={label}
      disabled={!editMode}
      defaultValue={defaultValue}
      value={value}
      variant="outlined"
      onChange={onChange}
      onKeyDown={onKeyDown}
      onWheel={(e) => e.target.blur()}
      fullWidth
      size={size}
      sx={{ ...sx }}
      slotProps={{
        input: inputProps,
        htmlInput: {
          inputMode: allowDecimal ? "decimal" : "numeric",
          step: allowDecimal ? "any" : "1",
          ...numberProps,
        },
        inputLabel: inputLabelProps,
      }}
    />
  );
};

export default DecimalTextField;
