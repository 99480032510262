/*********************************************************
 This component will display .pdf, .jpg, .jpeg, .png files
 in a grid 3 wide.
 ********************************************************/
import React, { useState } from "react";
import { CircularProgress, Grid2, IconButton, Stack } from "@mui/material";
import CustomModal from "./modals/CustomModal";
import PDFViewer from "./PDFViewer";
import PDFPreview from "./PDFPreview";
import useImage from "../../customHooks/image/useImage";
import DownloadIcon from "@mui/icons-material/Download";
import PrintIcon from "@mui/icons-material/Print";
import VisibilityIcon from "@mui/icons-material/Visibility";

const FileList = ({ files }) => {
  const [file, setFile] = useState(null);
  const [fileContentType, setFileContentType] = useState(null);
  const [fileAlt, setFileAlt] = useState(null);
  const [fileOpen, setFileOpen] = useState(false);

  const closeFileModal = (event, reason) => {
    if (reason !== "backdropClick") setFileOpen(false);
  };

  const openFileModal = (file, fileURL, alt) => {
    setFileContentType(file.contentType);
    setFileAlt(alt);
    setFile(fileURL);
    setFileOpen(true);
  };

  return (
    <Grid2 container>
      <CustomModal open={fileOpen} close={closeFileModal}>
        <Grid2 container className="centered-container">
          {fileContentType === "image/png" ||
          fileContentType === "image/jpg" ||
          fileContentType === "image/jpeg" ||
          fileContentType === "image/gif" ? (
            <img src={file} alt={fileAlt} width="100%" />
          ) : (
            <PDFViewer file={file} />
          )}
        </Grid2>
      </CustomModal>
      {files.map((file, i) => (
        <Grid2
          key={file.fileID}
          size={{
            xs: 12,
            md: 4,
          }}
        >
          <DisplayFile
            key={file.fileID}
            fileToDisplay={file}
            i={i}
            openFileModal={openFileModal}
          />
        </Grid2>
      ))}
    </Grid2>
  );
};

const DisplayFile = ({ fileToDisplay, i, openFileModal }) => {
  const { file, isLoading } = useImage(fileToDisplay.fileID);

  const printFile = (file) => {
    const fileWindow = window.open(file);
    fileWindow.onload = () => {
      fileWindow.print();
    };
  };

  const renderButtons = () => (
    <Stack spacing={1} direction="row" sx={{ mt: 0.5 }}>
      <IconButton
        color="primary"
        onClick={() => openFileModal(fileToDisplay, file, "file" + i)}
      >
        <VisibilityIcon />
      </IconButton>
      <IconButton color="primary" download={""} href={file}>
        <DownloadIcon />
      </IconButton>
      <IconButton color="primary" onClick={() => printFile(file)}>
        <PrintIcon />
      </IconButton>
    </Stack>
  );

  return fileToDisplay.contentType === "image/png" ||
    fileToDisplay.contentType === "image/jpg" ||
    fileToDisplay.contentType === "image/jpeg" ||
    fileToDisplay.contentType === "image/gif" ? (
    <Grid2
      container
      key={fileToDisplay.fileID}
      className="centered-container"
      size={{
        xs: 12,
        md: 4,
      }}
    >
      {isLoading ? (
        <CircularProgress />
      ) : (
        <>
          <Grid2 sx={{ height: 150 }} size={12}>
            <img src={file} alt={"img" + i} style={{ height: "100%" }} />
          </Grid2>
          <Grid2 sx={{ mt: 0.5 }} size={4}>
            {renderButtons()}
          </Grid2>
        </>
      )}
    </Grid2>
  ) : (
    <Grid2
      container
      key={fileToDisplay.fileID}
      className="centered-container"
      size={12}
    >
      {isLoading ? (
        <CircularProgress />
      ) : (
        <>
          <PDFPreview file={file} />
          {renderButtons()}
        </>
      )}
    </Grid2>
  );
};

export default FileList;
