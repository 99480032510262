import React from "react";
import { Autocomplete, Grid2, TextField } from "@mui/material";
import { getContactOptionLabel } from "../../sharedFunctions/labels";
import useAvailableShippersForFacility from "../../customHooks/facility/useAvailableShippersForFacility";

const SuperUserShipperSelection = ({ order, shipper, setShipper, size }) => {
  const shipperCompanies = useAvailableShippersForFacility(
    order.facility,
    order.warehouse?.contact?.address,
    order.freightShipment
  );

  return (
    <Grid2
      size={{
        xs: size === "lg" ? 12 : 6,
        md: size === "lg" ? 12 : 4,
      }}
    >
      <Autocomplete
        options={shipperCompanies.map(
          (shipperCompany) => shipperCompany.contact
        )}
        getOptionLabel={getContactOptionLabel}
        isOptionEqualToValue={(option, value) => {
          if (!value) return [];
          return option._id === value._id;
        }}
        value={shipper}
        onChange={(e, value) => {
          setShipper(value);
        }}
        renderInput={(params) => (
          <TextField {...params} label="Shipper" variant="outlined" />
        )}
        loading={shipperCompanies.length === 0}
      />
    </Grid2>
  );
};

export default SuperUserShipperSelection;
