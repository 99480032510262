import { useMutation, useQueryClient } from "@tanstack/react-query";
import { supplierConfirmOrder } from "../../api/productOrderAPI";

const useSupplierConfirmOrder = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: supplierConfirmOrder,
    onSuccess: (data) => {
      queryClient.invalidateQueries({
        queryKey: ["notifications"],
      });
      queryClient.invalidateQueries({
        queryKey: ["orders"],
      });
      queryClient.setQueryData(["order", data._id, data.orderNumber], data);
    },
  });
};

export default useSupplierConfirmOrder;
