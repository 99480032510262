import { createSlice } from "@reduxjs/toolkit";

export const userLevelsSlice = createSlice({
  name: "shippingRoutes",
  initialState: {
    shippingRoutes: [],
    shipments: [],
  },
  reducers: {
    setShippingRoutes: (state, action) => {
      state.shippingRoutes = action.payload;
    },
    setShipments: (state, action) => {
      state.shipments = action.payload;
    },
  },
});

export const { resetShippingRoutes, setShippingRoutes, setShipments } =
  userLevelsSlice.actions;

export default userLevelsSlice.reducer;

export const selectShippingRoutes = (state) => {
  return state.shippingRoutes.shippingRoutes;
};
export const selectShipments = (state) => {
  return state.shippingRoutes.shipments;
};
